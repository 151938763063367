$primary: #fe7d86;
$secondary: #707070;
$bg: #222a2f;
$h: 30px;
$w: 30px;

.toggle-switch-container {
	display: flex;
	position: relative;
	.switch {
		position: relative;
		display: inline-block;
		width: 60px;
		height: 34px;
		margin: 0 10px;
	}

	.slider {
		height: 31px;
		width: 67px;
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: #ccc;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}

	.slider:before {
		position: absolute;
		content: '';
		height: $h;
		width: $w;
		left: 1px;
		bottom: 0px;
		right: 0px;
		background-color: white;
		-webkit-transition: 70ms;
		transition: 70ms;
	}

	.checkbox {
		width: 100%;
		height: 100%;
	}
	.checkbox:checked + .slider {
		background-color: $primary;
	}

	.checkbox:focus + .slider {
		box-shadow: 0 0 1px $primary;
	}

	.checkbox:checked + .slider:before {
		-webkit-transform: translateX(28px);
		-ms-transform: translateX(28px);
		transform: translateX(28px);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		height: 31px;
		width: 31px;
		border-radius: 50%;
	}
}
