.table-list {
    width: 100%;
    height: 168px;
    color: white;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    font-size: 12px;
    overflow-y: auto;
    text-align: center;

    label {
        margin-top: 10px;
        color: #bcbcbc;
    }

    .table-list-item {
        height: 42px;
        cursor: pointer;
        padding: 13px;
        font-weight: 600;
        position: relative;

        &:hover {
            background: linear-gradient(0deg, #ff8585 0%, #ff7192 100%);
            .list-center {
                label {
                    color: white;
                }
            }

            .list-right {
                label {
                    color: white;
                }
            }
        }

        .list-left {
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-50%);
            label {
                cursor: pointer;
            }
        }

        .list-center {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            label {
                color: #CCCCCC;
                cursor: pointer;
            }
        }

        .list-right {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);

            label {
                cursor: pointer;
                color: #CCCCCC;
                font-size: 12px;
                font-weight: 600;
            }
        }

        &--selected {
            background: linear-gradient(0deg, #ff8585 0%, #ff7192 100%);
            .list-center {
                label {
                    color: white;
                }
            }

            .list-right {
                label {
                    color: white;
                }
            }
        }
    }
}