.booking-list-container-footer {
	margin-left: 10px;
	color: white;
	.segmented-control {
		position: relative;
		height: 50px;
		width: 329px;
		border-radius: 10px;
		background: #222a2f;
		z-index: 2;
		box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
	}

	input {
		display: none;
	}

	#bckgrnd,
	.labels {
		width: 82px;
		height: 50px;
		text-align: center;
		display: inline-block;
		padding-top: 10px;
		// margin-right: -3px;
		z-index: 2;
		cursor: pointer;
		// outline: 1px solid green;
	}

	#bckgrnd {
		border-radius: 10px;
		background: #222a2f;
		box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
		position: absolute;
		margin: 2px;
		left: 0;
		top: 0;
		z-index: -1;
	}

	#rad1:checked ~ #bckgrnd {
		transform: translateX(0);
		transition: transform 0.2s ease-in-out;
	}
	#rad2:checked ~ #bckgrnd {
		transform: translateX(81px);
		transition: transform 0.2s ease-in-out;
	}
	#rad3:checked ~ #bckgrnd {
		transform: translateX(162px);
		transition: transform 0.2s ease-in-out;
	}

	#rad4:checked ~ #bckgrnd {
		transform: translateX(244px);
		transition: transform 0.2s ease-in-out;
	}
}
