.container-fluid {
  padding: 0;
  // background: #fff;
  overflow: hidden;
}

.fluid-max-width {
  max-width: 1400px;
}

.inline-middle {
  display: flex;
  align-items: center;
}

.main-navbar {
  &.is-home {
    .nav-link {
      padding-right: 0 !important;
      padding-left: 0 !important;
      color: var(--text-primary) !important;
      margin: 0 1rem;
      font-size: 0.9rem;
    }
  }
  .nav-link {
    color: var(--text-primary);
    text-decoration: none;
    font-size: 0.9rem; // Remove if regressions
    font-weight: 400;
    margin-left: 24px;
    margin-right: 24px;

    &:hover {
      text-decoration: underline;
    }
  }
  .navbar-brand {
    margin-left: 0;
  }
  .ml-auto {
    a {
      padding-top: 13px;
    }
  }
  .b-nav {
    height: 28px;
    border-right: 1px solid #cccccc;
    margin-top: 9px;
  }
}

.btn-start {
  background: var(--primary-color);
  border-radius: 31px;
  font-size: 0.9rem;
  font-weight: 500;
  border: none;
  padding: 12px 30px;
  color: #fff;

  &:hover {
    text-decoration: none;
    background: linear-gradient(153deg, var(--primary-color), #f5dca3);

    background-size: 150% 100%;
    animation: gradient 5s ease infinite;
  }
  &:active {
    border: none !important;
    color: #ffffff !important;
    outline: none !important;
    outline-offset: none !important;
  }
  &:link {
    border: none !important;
    color: #ffffff !important;
    outline: none !important;
    outline-offset: none !important;
  }
  &:focus {
    box-shadow: none !important;
    background: var(--primary-color) !important;
    border: none !important;
    color: #ffffff !important;
    outline: none !important;
    outline-offset: none !important;
  }
}

.circle-p {
  width: 60px;
  height: 60px;
  background: transparent linear-gradient(141deg, #fc277e 0%, #f6dba3 100%) 0%
    0% no-repeat padding-box;
  border-radius: 24px;
  box-shadow: 0px 10px 10px #00000029;
  padding-top: 16px;
}
.svg-1 {
  fill: #fff;
  width: 26px;
  height: 26px;
  margin: auto;
}

.input-signup {
  background: transparent;
  box-shadow: inset -4px -4px 12px #ffffff1d;
  border: none;
  border-radius: 10px;
  padding: 20px;
  height: 50px;
  // color: #6a6a6a;
  color: #ffffff;
  &:focus {
    background: transparent;
    outline: none !important;
    outline-offset: none !important;
    box-shadow: inset -4px -4px 12px #ffffff1d;
  }
}

.divider-s {
  height: 3px;
  box-shadow: inset -1px -1px 4px #ffffff1d;
  margin-top: 29px;
  border-radius: 10px;
}

.shadow-inp {
  box-shadow: inset 2px 2px 10px #000000;
  border-radius: 10px;
}

.form-control {
  &::-webkit-input-placeholder {
    color: #6a6a6a;
  }
}
// .form-control::-webkit-input-placeholder {
// 	color: #6a6a6a;
// }

.btn-signup {
  height: 42px;
  background: transparent linear-gradient(270deg, #ff8585 0%, #ff7192 100%) 0%
    0% no-repeat padding-box;
  border-radius: 21px;
  display: block;
  width: 100%;
  border: none;
  box-shadow: 5px 5px 15px #00000099;
  font-size: 16px;
}

.signup-form {
  hr {
    margin-top: 30px;
    box-shadow: 0px 0px 3px #000000cc;
  }
  p {
    font-size: 14px;
    color: #bcbcbc;
    font-family: "Helvetica";
    text-align: center;
    span {
      color: #ffffff;
    }
    a {
      color: #ffffff !important;
      font-weight: 100;
      letter-spacing: 0px;
    }
  }
}

html {
  scroll-behavior: smooth;
}

.payment {
  padding: 0;
  border-radius: 10px;
  h1 {
    font-size: 24px !important;
    margin-bottom: 30px;
  }
  h3 {
    font-size: 16px !important;
    color: #000000;
  }
  .btn-confirm {
    font-family: "Akkurat";
    font-weight: bold;
    width: 246px;
    height: 55px;
    background: #000000 !important;
    border-radius: 10px !important;
    border: none !important;
    color: #ffffff;
    margin-top: 33px;
    text-align: center;
    text-transform: uppercase;
  }
  .btn-add-subs {
    font-family: "Akkurat";
    font-weight: bold;
    text-transform: uppercase;
    width: 244px;
    height: 55px;
    background: transparent !important;
    border-radius: 10px !important;
    color: #b38852;
    border: 2px solid #b38852;
    text-align: center;
    &:hover {
      background: #b38852 !important;
      color: #ffffff !important;
    }
  }
  .box-payment {
    img {
      text-align: center;
      margin: auto;
      margin-bottom: 20px;
    }
    .row {
      margin-bottom: 13px;
      margin-left: 0;
      margin-right: 0;
      justify-content: space-between;
    }
    h4 {
      font-size: 14px;
      margin-bottom: 0 !important;
    }
    span {
      font-size: 16px;
      color: var(--text-secondary);
    }
    input,
    select {
      height: 50px;
      font-family: "Roboto";
    }
    input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #b3b3b3;
      opacity: 1; /* Firefox */
    }
    span.sp {
      display: inline-block;
      margin-top: 14px;
      margin-left: 35px;
      color: #484750;
      font-size: 16px;
      font-family: "Roboto";
    }
  }
}

.modal-pay {
  .modal-content {
    max-width: 661px;
    margin: auto;
    border-radius: 10px;
  }
  .txt-pay {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    margin-top: 25px;
    color: #999999;
  }
  .txt-pay2 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    margin-top: 25px;
    color: #999999;
  }
}

.btn-pay {
  width: 148px;
  height: 50px;
  border-radius: 30px;
  background: var(--primary-color);
  font-family: "Helvetica";
  font-size: 14px;
  font-weight: bold;
  border: none;
}

.btn-coupon {
  width: 148px;
  height: 50px;
  border-radius: 30px;
  background: #febe7d;
  font-family: "Helvetica";
  font-size: 14px;
  font-weight: bold;
  border: none;
}

.sidebar-pos-settings {
  background: var(--background-white);
  padding: 20px;
  left: -1px !important;

  .w-avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: var(--primary-color);
    color: #ffffff;
    font-size: 12px;
    align-items: center;
    box-shadow: 3px 2px 4px 0 rgba(0, 0, 0, 0.19),
      -8px -8px 12px 0 rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    border: 4px solid white;
  }
  .clip-circle {
    max-width: fit-content;
    clip-path: ellipse(16px 16px at 20px 20px);
    /* OLD VALUE example: circle(245px, 140px, 50px); */
    /* Yep, even the new clip-path has deprecated stuff. */
  }

  h5 {
    color: #cccccc;
    font-size: 12px;
    font-family: "Helvetica";
  }

  h5.business-name {
    color: #4d4d4d;
    font-weight: bold;
  }

  h5.business-address {
    color: #4d4d4d;
  }

  .menus {
    font-size: 12px;
    font-weight: bold;
  }

  .menu-item {
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 5px;
  }
}

// Date shift selector

.date-shift-selector {
  background-color: white;
  border-radius: 20px;
  height: 30px;
  // width: 250px;
  box-shadow: 5px 4px 11px -1px #e0e0e0;
  margin-top: 20px;
  display: inline-flex;
  padding: 8px 10px 0 10px;
  z-index: 9;
  position: absolute;
}

.date-shift-selector .date-selector {
  font-size: 12px;
  margin: -2px 10px 0 10px;
  cursor: pointer;
  user-select: none;
}

.date-shift-selector .shift-selector {
  font-size: 12px;
  margin: -2px 10px 0 10px;
  cursor: pointer;
  user-select: none;
}

.date-shift-selector .bg-shift-indicator {
  width: 25px;
  height: 25px;
  padding: 7px;
  cursor: pointer;
  border-radius: 50%;
  margin-top: -5px;

  .shift-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #30eb8e;

    &.no-shift {
      background: #eb3030;
    }
  }
}

// Dail View Sales

.daily-view-sales {
  position: absolute;
  inset: 0;
  overflow-y: auto;
  transition: none 0s ease 0s;
  margin: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.daily-view-sales .main-container {
  width: 500px;
  height: 310px;
  background-color: #f3f2f7;
  box-shadow: 5px 4px 11px -1px #e0e0e0;
  border-radius: 20px;
}

.daily-view-sales .main-container .logo-header {
  text-align: right;
  margin: 10px 20px 0 0;
}

.daily-view-sales .main-container .sales-header {
  font-weight: bold;
  margin: 0 0 0 20px;
}

.daily-view-sales .main-container .main-items {
  font-weight: bold;
  margin: 0 20px 0 20px;
  margin-top: 20px;
  border-bottom: 1px solid #e0e0e0;
}

.daily-view-sales .main-container .total-items {
  font-weight: bold;
  margin: 0 20px 0 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #689af8;
}

.check-btn {
  background-color: #f3f2f7;
  display: inline;
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  position: absolute;
  bottom: 20px;
  right: 18px;
  box-shadow: 5px 4px 11px -1px #e0e0e0;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.check-btn--save {
  background-color: var(--primary-color);
  display: inline;
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  position: absolute;
  bottom: 20px;
  right: 18px;
  box-shadow: 5px 4px 11px -1px #e0e0e0;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
  color: white;
}

.check-btn--cancel {
  background-color: #f3f2f7;
  display: inline;
  padding: 10px 15px 10px 15px;
  border-radius: 10px;
  position: absolute;
  bottom: 20px;
  right: 100px;
  box-shadow: 5px 4px 11px -1px #e0e0e0;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.hide-btn {
  visibility: hidden;
}

.sidebar {
  //position: fixed;
  background: var(--text-primary);
  // top: 0;
  // bottom: 0;
  // width: 300px;
  left: -1px !important;
  //min-height: 100vh !important;
  //z-index: 100;
  padding: 20px;
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .w-avatar {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: var(--primary-color);
    color: #ffffff;
    font-size: 12px;
    padding: 10px;
    padding-top: 6px;
  }
  h5 {
    color: #cccccc;
    font-size: 12px;
    font-family: "Helvetica";
  }
  .icon-db {
    width: 15px;
    height: 15px;
    fill: #ffffff;
  }
  h4 {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    margin-left: 25px;
    margin-bottom: 0;
  }
  .logout {
    font-size: 14px;
    bottom: 43px;
    left: 49px;
    color: #cccccc;
    position: absolute;
  }
  .shadow-list-active {
    box-shadow: -5px -5px 15px #ffffff14;
    border-radius: 25px;
    margin-bottom: 20px;
    .icon-db {
      fill: #ffffff;
    }
    .list-db {
      padding: 18px 30px;
      border-radius: 25px;
      box-shadow: 5px 5px 15px #00000099;
    }
    h4 {
      color: #ffffff;
    }
  }

  .shadow-list {
    border-radius: 25px;
    margin-bottom: 20px;
    .list-db {
      padding: 18px 30px;
      border-radius: 25px;
    }
    .icon-db {
      fill: var(--text-secondary);
    }
    h4 {
      color: var(--text-secondary);
    }
  }
}
// #sidebar-wrapper{
//     min-height: 100vh !important;
//     width: 300px;
//     margin-left: -1rem;
//     -webkit-transition: margin .25s ease-out;
//     -moz-transition: margin .25s ease-out;
//     -o-transition: margin .25s ease-out;
//     transition: margin .25s ease-out;
// }
// #sidebar-wrapper .sidebar-heading {
//     font-size: 1.2rem;
// }

#mob-d {
  display: none;
}

#pricing {
  background: linear-gradient(
    90deg,
    rgba(236, 64, 126, 1) 0%,
    rgba(241, 139, 145, 1) 35%,
    rgba(245, 219, 163, 1) 100%
  );
}

#page-content-wrapper {
  .edit-a {
    margin-left: 30px;
  }
  .box-db {
    h5 {
      color: #484750;
      font-size: 18px;
    }
    .media-body {
      margin-left: 26px;
      margin-top: 18px;
    }
    .w-icon {
      width: 56px;
      height: 56px;
      border-radius: 28px;
      background: var(--primary-color);
    }
  }
  .d1 {
    span {
      display: block;
      margin-bottom: 17px;
      font-weight: 800;
      font-size: 16px;
      color: #484750;
    }
  }
  .d2 {
    span {
      display: block;
      margin-bottom: 17px;
      font-family: "Helvetica";
      font-size: 16px;
      color: #999999;
      margin-bottom: 17px;
    }
    a {
      color: var(--primary-color) !important;
      text-decoration: underline;
      display: inline-block;
    }
  }
}

.widget {
  h4 {
    font-size: 18px;
    font-weight: bold;
  }
  h3 {
    font-size: 24px;
    color: var(--text-primary);
    font-family: "Helvetica";
  }
  p {
    color: #999999;
    font-size: 16px;
    font-family: "Helvetica";
    margin-top: 16px;
  }
  .w-tab1.active-tab,
  .w-tab2.active-tab {
    background: #ffffff;
    border: none;
    color: var(--primary-color);
    font-weight: 800;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 0;
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 20px;
  }
  .w-tab1,
  .w-tab2 {
    background: #ffffff;
    border: none;
    color: var(--text-secondary);
    font-weight: 800;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 0;
    padding-bottom: 20px;
  }
  .w-select {
    width: 169px;
    height: 57px;
    padding-right: 10px;
    select {
      width: 100%;
      height: 57px;
      border: none;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      &:focus {
        box-shadow: none !important;
        outline: none !important;
        outline-offset: none !important;
      }
    }

    border-right: 1px solid #cccccc;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  #search-table {
    border: none;
    height: 60px;
    background: var(--primary-color);
    border-radius: 0px 30px 30px 0px;
    font-family: "Helvetica";
    font-size: 16px;
    width: 168px;
  }

  .btn-clock {
    background: var(--primary-color);
    border-radius: 10px;
    padding: 5px 23px;
    font-size: 16px;
    border: none;
    height: 40px;
    font-family: "Helvetica";
  }
  code {
    background: #dbdbdb;
    border-radius: 10px;
    padding: 27px 47px;
    display: block;
    width: 100%;
    margin: auto;
    margin-top: 28px;
    p {
      color: #4a72fa;
      font-size: 18px;
      font-family: "Helvetica";
    }
  }
}

.grid-img {
  display: block;
  background-image: url("../images/grid.png");
  padding: 29px 124px;
  margin: auto;
  margin-top: 20px;
  width: 100%;
}

#sel-wid {
  width: 241px;
  height: 40px;
  margin-top: 48px;
  border-radius: 30px;
  .caret {
    position: absolute;
    top: calc(50% - 1px);
  }
}

.sld2 {
  width: 485px;
  height: 364px;
  border-radius: 10px;
  .slick-list {
    height: 364px;
    border-radius: 10px;
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-dots {
    left: -66px;
    top: 40%;
    width: 20px;
    li {
      width: 4px;
      height: 12px;
      background: #cccccc;
      border-radius: 2px;
      a {
        width: 4px;
        height: 12px;
        background: #cccccc;
        border-radius: 2px;
        display: block;
      }
    }
    li.slick-active {
      width: 4px;
      height: 24px;
      background: var(--primary-color);
      border-radius: 2px;
      a {
        width: 4px;
        height: 24px;
        background: var(--primary-color);
        border-radius: 2px;
        display: block;
      }
    }
  }
}

.sld3 {
  .slick-list {
    margin: auto;
    width: 80%;
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-center {
    padding: 0 !important;
  }
  .sld3-c {
    width: 100%;
  }
  .slick-slide {
    height: auto;
  }
}

#row-p {
  margin-right: 62px;
  margin-left: 62px;
}

.forget-form {
  h2 {
    color: #fff !important;
    font-size: 24px;
    font-family: "Helvetica";
    text-align: center;
    margin-bottom: 36px;
  }
}

.edit-profile {
  p {
    font-family: "Helvetica";
    font-weight: bold;
    font-size: 14px;
    color: #4d4d4d;
  }
  .form-control::-webkit-input-placeholder {
    color: #cccccc;
  }
  .edit-inp {
    background: #ffffff !important;
    border: 1px solid #cccccc;
    border-radius: 10px;
    margin-bottom: 8px;
  }
}

.go-back {
  margin-bottom: 0;
  margin-left: 35px;
  a {
    color: var(--primary-color) !important;
    font-family: "Helvetica";
    font-weight: bold;
    font-size: 14px;
  }
}

.btn-copy {
  background: var(--primary-color);
  border-radius: 30px;
  padding: 16px 54px;
  font-size: 16px;
  border: none;
  height: 50px;
  font-family: "Helvetica";
  font-weight: 800;
  font-size: 14px;
  letter-spacing: 1px;
  align-self: "flex-end";
  margin-top: 10px;
  &:hover {
    color: #f7f7f7;
    text-decoration: none;
    background: linear-gradient(
      153deg,
      var(--primary-color) 0%,
      #f6dba3 100%
    ) !important;
  }
  &:link {
    border: none !important;
    color: #ffffff !important;
    outline: none !important;
    outline-offset: none !important;
  }
  &:focus {
    box-shadow: none !important;
    background: var(--primary-color) !important;
    border: none !important;
    color: #ffffff !important;
    outline: none !important;
    outline-offset: none !important;
  }
}

.footer {
  a {
    color: #cccccc !important;
  }
}

.cpc {
  padding: 45px 38px 15px;
}

.confirm-plan {
  padding: 33px 45px;
  background: var(--primary-color) 19;
  text-align: center;
  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  h3 {
    font-size: 24px;
    color: var(--primary-color);
  }
  p {
    font-family: Helvetica;
    font-size: 16px;
    color: var(--text-secondary);
    margin-top: 25px;
  }
  margin-bottom: 15px;
}

.btn-cancel {
  padding: 16px;
  background: #cccccc;
  font-size: 16px;
  width: 100%;
}

.btn-confirm {
  padding: 16px;
  font-size: 16px;
  width: 100%;
}

.suspend {
  margin-top: 40px;
  color: var(--primary-color);
  text-align: center;
  font-family: Helvetica;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

// SignUp page
// Don't remove yet, I caused some regressions

.price-cont {
  box-shadow: -5px -5px 6px #ffffff;
  border-radius: 10px;
}

.price-plan {
  box-shadow: 5px 5px 6px #00000029;
  border-radius: 10px;
  height: 440px;
  padding: 40px 30px;

  .img-md {
    max-width: 160px;
    margin: 0 auto;
    margin-bottom: 40px;
  }
  h4 {
    color: #222a2f;
    font-family: "HelveticaNeueBold";
    font-size: 24px;
  }
  span {
    color: #ffffff;
    font-weight: bold;
    font-size: 30px;
  }
  .price-tag {
    // font-family: 'HelveticaNeueMedium';
    font-size: 65px;
    color: #ffffff;
  }
  .plan-description {
    text-align: left !important;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
  }
  .btn-start {
    display: block;
    margin: auto;
    // disable hover style
    // &:hover {
    // 	background: transparent linear-gradient(153deg, #fe7d86 0%, #f6dba3 100%) 0% 0% no-repeat
    // 		padding-box !important;
    // }
  }
  ul {
    list-style: none;
    padding: 0;
    margin-top: 25px;
    li {
      font-family: "Helvetica";
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}

.btn-tab1.active-tab,
.btn-tab2.active-tab {
  font-family: Helvetica;
  background: #fe7d86;
  color: #ffffff;
  box-shadow: 5px 5px 6px #00000029;
  border: none;
  &:hover {
    background: #fe7d86 !important;
    color: #ffffff !important;
  }
  &:link {
    background: #fe7d86 !important;
    color: #ffffff !important;
    outline: none !important;
    outline-offset: none !important;
  }
  &:focus {
    background: #fe7d86 !important;
    color: #ffffff !important;
    outline: none !important;
    outline-offset: none !important;
    box-shadow: none !important;
  }
  &:active {
    background: #fe7d86 !important;
    color: #ffffff !important;
    outline: none !important;
    outline-offset: none !important;
  }
}

.btn-tab1,
.btn-tab2 {
  width: 176px;
  font-size: 16px;
  font-family: Helvetica;
  background: #f7f7f7;
  color: #707070;
  border-radius: 10px;
  box-shadow: inset 5px 5px 5px #ddd;
  padding: 21px 0;
  border: none;
  &:hover {
    background: #f7f7f7;
    color: #707070;
  }
  &:link {
    background: #f7f7f7 !important;
    color: #707070 !important;
    outline: none !important;
    outline-offset: none !important;
  }
  &:focus {
    background: #f7f7f7 !important;
    color: #707070 !important;
    outline: none !important;
    outline-offset: none !important;
    box-shadow: none !important;
  }
  &:active {
    background: #f7f7f7 !important;
    color: #707070 !important;
    outline: none !important;
    outline-offset: none !important;
  }
}

.plan-signup {
  .btn-tab1.active-tab,
  .btn-tab2.active-tab {
    background: #fe7d86;
    color: #ffffff;
  }

  .btn-tab1,
  .btn-tab2 {
    background: #222a2f;
    box-shadow: inset -2px -2px 2px #707070;
  }

  .price-plan {
    height: auto;
    /* border-radius: 36px; */
    padding-top: 49px;
    padding-bottom: 45px;
    /* box-shadow: 5px 5px 15px rgb(0 0 0 / 50%); */
    text-align: center;
    box-shadow: none;
    h4 {
      color: #f7f7f7;
    }
    ul {
      li {
        color: #f7f7f7;
      }
    }
    .price-tag {
      color: #f7f7f7;
    }
    .plan-description {
      text-align: left !important;
    }
  }
  .price-cont {
    box-shadow: -5px -5px 15px #ffffff0d;
    border-radius: 36px;
  }
}

.seventh-content {
  .nav-tabs {
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    border: none;
  }
}
.custom-button {
  // background: var(--primary-color);
  /* border-radius: 31px; */
  font-size: 0.9rem;
  font-weight: 500;
  // border: none;
  padding: 12px 30px;
  color: #fff;
  background: linear-gradient(
    119.22deg,
    #f76b1c 24.06%,
    #f9af46 77.87%,
    #fbda61 112.36%
  );
  font-weight: bold;
  // width: 100%;

  &:hover {
    background: #fff;
    color: #f76b1c;
    border: 1px solid #f76b1c !important;
  }
  &:active {
    // border: none !important;
    color: #f76b1c !important;
    outline: none !important;
    outline-offset: none !important;
  }
  &:link {
    // border: none !important;
    color: #f76b1c !important;
    outline: none !important;
    outline-offset: none !important;
  }
  &:focus {
    box-shadow: none !important;
    background: #fff !important;
    border: 1px solid #f76b1c !important;

    // border: none !important;
    color: #f76b1c !important;
    outline: none !important;
    outline-offset: none !important;
  }
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #222a2f;
}

::-webkit-scrollbar-thumb {
  width: 5px;
  height: 5px;
  border-radius: 20px;
  background-color: #2a343a;
}

::-webkit-scrollbar-corner {
  display: none;
}

.btn-start-outlined {
  background: #fff;
  border-radius: 31px;
  font-size: 0.9rem;
  font-weight: 500;
  border: 2px solid #f76b1c;
  padding: 12px 30px;
  color: #f76b1c;

  &:hover {
    text-decoration: none;
    background: linear-gradient(
      119.22deg,
      #f76b1c 24.06%,
      #f9af46 77.87%,
      #fbda61 112.36%
    );
    border: 2px solid #f76b1c;
    background-size: 150% 100%;
    animation: gradient 5s ease infinite;
    color: #ffffff !important;
    font-size: 0.9rem;
  }
  &:active {
    // border: none !important;
    color: #ffffff !important;
    outline: none !important;
    outline-offset: none !important;
  }
  &:link {
    // border: none !important;
    // color: #ffffff !important;
    outline: none !important;
    outline-offset: none !important;
  }
  &:focus {
    box-shadow: none !important;
    // background: var(--primary-color) !important;
    // border: none !important;
    // color: #ffffff !important;
    outline: none !important;
    outline-offset: none !important;
  }
}
