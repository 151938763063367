$tablet-width: 768px;
$desktop-width: 1024px;
$phone-width: 480px;

@media (min-width: 990px) and (max-width: 1200px) {
	.home-title h2 {
		margin-top: 20px !important;
	}
	#row-p {
		margin: 0;
	}
}

@media (max-width: 990px) {
	.home-title h2 {
	}
	#hero-img {
	}
	.sc-bxivhb {
		margin-top: 150px;
	}
}

@media (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px}) {
	.main-navbar a {
		font-size: 12px;
	}
	#hero-img {
	}
	.home-title h2 {
	}
	.sc-bxivhb {
		margin-top: 100px;
	}
	.sc-ifAKCX {
		padding-top: 0;
	}
	.next-arrow {
		display: none !important;
	}
	.prev-arrow {
		display: none !important;
	}
	.sld2 {
		width: 367px;
		height: 277px;
	}
	.img-cont {
		height: 450px !important;
	}
	.price-cont {
		margin-top: 20px;
	}
	.footer {
		.navbar-nav {
			flex-direction: column;
		}
		#basic-navbar-nav {
			margin: 0 !important;
			a {
				margin-left: 0;
			}
		}
	}
	.sld1 {
		.slick-slide {
			height: 386px;
		}
	}
	#row-p {
		margin-right: 0px;
		margin-left: 0px;
	}
	.box-db {
		margin-bottom: 20px;
	}
	.jWTtfy {
		height: auto;
	}
	.grid-img {
		padding: 20px;
		margin: auto;
		margin-top: 20px;
		width: 100%;
		overflow-x: scroll;
	}
}

@media (max-width: 800px) {
	#mob-d {
		display: flex;
	}
}

@media (max-width: #{$phone-width}) {
	#mob-d {
		display: flex;
	}
	.home-title h2 {
	}
	#hero-img {
	}
	.sc-bxivhb {
		margin-top: 220px;
	}
	.img-cont {
		height: 299px !important;
	}
	.sc-ifAKCX {
		padding-top: 0;
	}
	.next-arrow {
		display: none !important;
	}
	.prev-arrow {
		display: none !important;
	}
	.price-cont {
		margin-top: 20px;
	}
	.footer {
		.navbar-nav {
			flex-direction: column;
		}
		#basic-navbar-nav {
			margin: 0 !important;
			a {
				margin-left: 0;
			}
		}
	}
	.sld1 {
		.slick-slide {
			height: 491px;
		}
	}
	.box-db {
		margin-bottom: 20px;
	}
	#row-p {
		margin-right: 20px;
		margin-left: 20px;
	}
	.edit-a {
		margin-left: 0px !important;
	}
	.grid-img {
		padding: 20px;
		margin: auto;
		margin-top: 20px;
		width: 100%;
		overflow-x: scroll;
	}
}
