.btn-control {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  color: #fff;
  background: #bcbcbc;
  border-color: #212a2f;
  font-weight: 500;
  // text-align: center;
  width: inherit;
  height: 51px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  user-select: none;
  // box-shadow: 13px 10px 20px 6px #161a1d9c;
  &:active {
    background: #1f262b;
    color: #ffffff;
    outline: none;
    outline-offset: none;
  }
  &--selected {
    background: #fe7d86;
    color: white;
  }

  &--seated {
    background: linear-gradient(0deg, #ff8585 0%, #ff7192 100%);
  }

  &--revert {
  }

  &--finished {
  }
}
