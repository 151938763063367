.edit-booking-small {
  font-family: "acumin-pro", sans-serif;
  section {
    width: 90%;
    background: white;
    color: black;
    border-radius: 10px;
    padding: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: -1px 0px 22px 1px rgba(179, 179, 179, 0.75);
    -webkit-box-shadow: -1px 0px 22px 1px rgba(179, 179, 179, 0.75);
    -moz-box-shadow: -1px 0px 22px 1px rgba(179, 179, 179, 0.75);
  }

  .header {
    display: flex;
    justify-content: center;
    color: #fe7d86;
    .restaurant-logo {
      background-color: blueviolet;
      border-radius: 50%;
      width: 50px;
      height: 50px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    .restaurant-name {
      font-size: 35px;
      font-family: sans-serif;
      font-weight: 700;
      margin-left: 15px;
    }
  }

  .middle {
    width: 100%;

    .middle-title {
      color: #fe7d86;
      text-align: center;
      font-size: 20px;
      font-family: sans-serif;
      font-weight: 700;
    }

    .booking-details {
      color: #707070;
      font-size: 14px;
      background-color: #f4f4f4;
      width: 100%;
      border-radius: 20px;
      padding: 10px;
      // padding-left: 20px;

      .row {
        display: flex;
        flex-wrap: wrap;
        /* margin-right: -15px; */
        margin-left: -15px;
      }

      .guest-name {
        font-weight: 600;
      }

      .column-center {
        text-align: left;
        padding-left: 20px;
      }
    }
  }
}

.edit-booking {
  font-family: "acumin-pro", sans-serif;
  section {
    width: 50%;
    background: white;
    color: black;
    border-radius: 10px;
    padding: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    box-shadow: -1px 0px 22px 1px rgba(179, 179, 179, 0.75);
    -webkit-box-shadow: -1px 0px 22px 1px rgba(179, 179, 179, 0.75);
    -moz-box-shadow: -1px 0px 22px 1px rgba(179, 179, 179, 0.75);
  }

  .header {
    display: flex;
    justify-content: center;
    color: #fe7d86;
    .restaurant-logo {
      background-color: blueviolet;
      border-radius: 50%;
      width: 50px;
      height: 50px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    .restaurant-name {
      font-size: 35px;
      font-family: sans-serif;
      font-weight: 700;
      margin-left: 15px;
    }
  }

  .middle {
    width: 100%;

    .middle-title {
      color: #fe7d86;
      text-align: center;
      font-size: 20px;
      font-family: sans-serif;
      font-weight: 700;
    }

    .booking-details {
      color: #707070;
      font-size: 14px;
      background-color: #f4f4f4;
      width: 100%;
      border-radius: 20px;
      padding: 10px;
      padding-left: 20px;

      .guest-name {
        font-weight: 600;
      }

      .column-center {
        text-align: left;
        padding-left: 70px;
      }
    }
  }
}

.MuiButton-containedPrimary {
  margin-right: 15px !important;
  /* background-color: #fe7d86 !important; */
  background: rgb(250, 81, 134);
  background: linear-gradient(
    90deg,
    rgba(250, 81, 134, 1) 0%,
    rgba(243, 214, 160, 1) 100%
  );
}
:root {
  --primalyPurple: #4059e5;
}
