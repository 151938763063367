@mixin resetList {
  margin: 0;
  padding: 0;
}

@mixin flexRow {
  display: flex;
  flex-direction: row;
}
@mixin flexCol {
  display: flex;
  flex-direction: column;
}

.reservation-det-wrapper {
  width: 100%;
  height: 578px;
  position: absolute;
  z-index: 5;
  // background: #424040d6;
  background: #222a2f;
  color: white;
  box-shadow: 4px 11px 13px 0px #161a1d, 1px 2px 12px 7px #273036;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #222a2f;
  }
  ::-webkit-scrollbar-thumb {
    width: 1px;
    height: 1px;

    border-radius: 20px;
    background-color: #2a343a;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }
  .row1 {
    @include flexRow();
    align-items: center;
    font-size: 28px;
    font-weight: bold;
    margin: 15px 20px 22px 11px;
    height: 30px;
    max-width: 100%;
    user-select: none;
    .exit-btn-wrapper {
      display: inline-flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: #ffffffd9;
      border-radius: 27%;
      top: 15px;
      right: 15px;
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin: 5px;
    }
  }
  .row2 {
    @include flexRow();
    padding: 0 0 0 10px;
    .header-text {
      padding: 0 36px 0 7px;
      text-decoration: underline;
    }
    .buttonWrapper {
      display: inline-flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      span {
        cursor: pointer;
      }
    }
    .buttonWrapper4 {
      @include flexRow();
      align-content: center;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 24px;
      .num-text {
        // margin-top: 2;
        @include resetList();
        user-select: none;
      }
    }
  }
  .row3 {
    @include flexRow();
    justify-content: space-between;
    align-content: center;
    .dropdown-wrapper {
      @include flexRow();
      justify-content: flex-start;
      align-items: center;
      width: 98%;
      height: 50px;
      // border: 1px solid white;
      border-radius: 5px;
      margin: 14px 5px 28px 7px;
      user-select: none;
      // .dot {
      // 	height: 20px;
      // 	width: 20px;
      // 	border: 1px solid #fe7d86;
      // 	border-radius: 50%;
      // 	background: #fe7d86;
      // 	margin: 0 0 0 15px;
      // }
      span {
        margin: 15px;
      }
    }
  }
  .content {
    @include flexCol();
    overflow: auto;
    height: 389px;
    padding: 0 20px 0 0;
    span {
      margin: 2px 0 2px 0;
      color: #bcbcbc;
      font-weight: bold;
      width: 100%;
      user-select: none;
    }
    .row {
      @include flexRow();
      flex-wrap: nowrap;
    }
    .row-notes {
      @include flexRow();
      flex-wrap: nowrap;
      padding-bottom: 45px;
      z-index: inherit;
    }
    h6 {
      @include flexRow();
      width: 225px;
      align-items: center;
      margin: 15px 0 15px 5px;
      color: #707070;
      font-weight: bold;
      user-select: none;
    }
    input {
      z-index: 0;
      padding: 10px;
      position: relative;
      width: 90%;
      margin: 5px;
      color: white;
      background: #222a2f;
      box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;
      border-radius: 15px;
    }
    .row4 {
      @include flexRow();
      justify-content: flex-start;
      align-items: center;
      margin: 2px;
      text-decoration: underline;
      // padding: ;
    }
    .row5 {
      @include flexRow();
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 30px 0;
      user-select: none;
      font-size: 15px;
    }
    p {
      font-size: 12px;
      color: #fffff270;
      padding-bottom: 20px;
    }
  }
}
