@mixin resetList {
	margin: 0;
	padding: 0;
}

@mixin flexRow {
	display: flex;
	flex-direction: row;
}
@mixin flexCol {
	display: flex;
	flex-direction: column;
}

// .container {
// 	@include flexRow();
// 	position: relative;
// 	width: 98%;
// 	height: 50px;
// 	border: 1px solid white;
// 	border-radius: 5px;

// 	.header {
// 		@include flexRow();
// 		height: 100%;
// 		width: 100%;
// 		justify-content: flex-start;
// 		align-content: center;
// 		align-items: center;
// 		position: relative;
// 		padding: 0 0 0 50px;
// 		color: white;

// 		.circle-icon {
// 			background-color: #fe7d86;
// 			position: absolute;
// 			left: 5px;
// 			border-radius: 50%;
// 			width: 25px;
// 			height: 25px;
// 			margin-right: 5px;
// 			margin-left: 5px;
// 			&--billed {
// 				background: #85ffbd;
// 			}
// 			&--unseat {
// 				background: #cccccc;
// 			}
// 		}

// 		.imgContainer {
// 			@include flexRow();
// 			justify-content: center;
// 			align-items: center;
// 			// background: blue;
// 			position: absolute;
// 			right: 2px;
// 		}
// 	}
// 	.drop-down-container {
// 		ul {
// 			position: absolute;
// 			width: 100%;
// 			height: 50px;
// 			right: 39.5px;
// 			top: 0px;
// 			.circle-icon {
// 				background-color: #fe7d86;
// 				border-radius: 50%;
// 				width: 25px;
// 				height: 25px;
// 				margin-right: 5px;
// 				margin-left: 5px;
// 			}
// 			li {
// 				display: flex;
// 				width: 112%;
// 				height: 50px;
// 				background: #ffffff;
// 				list-style: none;
// 				cursor: pointer;
// 				// margin-bottom: 0.8em;
// 				color: black;
// 				align-items: center;
// 				justify-content: flex-start;
// 				margin: 0;
// 				padding: 0 0 0 65px;
// 				border-bottom: 0.5px solid black;

// 				.circle-icon-list {
// 					background-color: #fe7d86;
// 					position: absolute;
// 					left: 55px;
// 					// border: 0.3px solid gray;
// 					border-radius: 50%;
// 					width: 25px;
// 					height: 25px;
// 					margin-right: 5px;
// 					margin-left: 5px;
// 					&--billed {
// 						background: #85ffbd;
// 					}
// 					&--unseat {
// 						background: #cccccc;
// 					}
// 				}
// 			}
// 		}
// 	}
// }

.imgContainer {
	@include flexRow();
	justify-content: center;
	align-items: center;
	// background: blue;
	position: absolute;
	right: 30px;
	color: #fe7d86;
}
.circle-icon {
	background-color: #fe7d86;
	position: relative;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	margin-right: 10px;
	&--billed {
		background: #85ffbd;
	}
	&--unseat {
		background: #cccccc;
	}
}
.circle-icon-list {
	background-color: #fe7d86;
	position: relative;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	margin-right: 10px;
	&--billed {
		background: #85ffbd;
	}
	&--unseat {
		background: #cccccc;
	}
}
