.booking-button {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

    padding: 12px 15px;
    text-align: center;
    cursor: pointer;
    color: white;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    margin-left: 10px;

    &:active {
        background: linear-gradient(0deg, #ff8585 0%, #ff7192 100%)
    }

    &--icon-button {
        min-width: 60px;
        min-height: 60px;
        max-width: 60px;
        max-height: 60px;
    }

    &--selected {
        background: linear-gradient(0deg, #ff8585 0%, #ff7192 100%)
    }
}