/* ----------------
   Variables
---------------- */

$primaryColor: #101827;
$secondaryColor: #1f2938;
$tertiaryColor: #34495e;
$pc: 768px;

/* ----------------
   Mixins 
---------------- */

@mixin pc {
  @media screen and (min-width: $pc) {
    @content;
  }
}
/* ----------------
   Functions 
---------------- */
/* ----------------
   Reset
---------------- */
p {
  margin: 0;
}
/* ----------------
   Utilities
---------------- */

.bg-primary {
  background: $primaryColor !important;
}
.bg-secondary {
  background: $secondaryColor !important;
}
.bg-danger {
  background: #ff655b !important;
}
.w-100 {
  width: 100% !important;
}
.neo-outer-glow {
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2),
    -3px -3px 6px rgba(255, 255, 255, 0.08) !important;
}

/* ----------------
   Layout
---------------- */
.body-wrapper {
  min-height: 100vh;
}
/* ----------------
   Components
---------------- */
.profile-icon {
  width: 48px;
  height: 48px;
  padding: 3px !important;

  .profile-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}
