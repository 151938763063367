.booking-employee-list-container {
	width: 100%;
	height: 100%;
	position: relative;

	.booking-employee-main-container {
		width: 544px;
		height: 313px;
		background: rgba(0, 0, 0, 0.6);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 10px;

		.booking-employee-list-item-container {
			width: 100%;
			display: grid;
			grid-template-rows: auto auto;
			grid-auto-columns: 78px;
			grid-auto-rows: 78px;
			grid-gap: 12px 12px;
			gap: 12px 12px;
			grid-auto-flow: column;
			overflow: auto;

			.employee-list-item {
				width: 78px;
				height: 78px;
				cursor: pointer;
				border-radius: 50%;
				background: #222a2f;
				padding: 10px;

				&--selected {
					background: white;
				}

				.inner {
					border-radius: 50%;
					width: 100%;
					height: 100%;
					text-align: center;
					padding: 16px 0;
					color: white;
					font-weight: 500;
				}
			}
		}
	}
}
