@mixin resetList {
	margin: 0;
	padding: 0;
}
@mixin flexRow {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}
@mixin flexCol {
	display: flex;
	flex-direction: column;
}
.modal-content-wrapper {
	width: 100%;
	height: 100%;
	position: relative;

	.modal-content-container {
		width: 468px;
		height: auto;
		background: rgba(0, 0, 0, 0.6);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 10px;
		border-color: none;

		.img-wrapper {
			display: inline-flex;
			align-content: center;
			justify-content: center;
			align-items: center;
			position: absolute;
			background-color: #ffffffd9;
			border-radius: 27%;
			top: 15px;
			right: 15px;
			cursor: pointer;
			width: 36px;
			height: 36px;
			margin: 5px;
		}
		.scrollable-container {
			height: 296px;
			width: 450px;
			margin: 15px;
			overflow-x: hidden;
			overflow-y: auto;
			color: #ffffff;
			.list-item-container {
				display: grid;
				grid-template-columns: auto auto auto;
				grid-auto-flow: row;
				grid-row-gap: 10px;
				overflow: auto;

				.time-booking-list-item {
					width: 132px;
					height: 60px;
					background: rgba(0, 0, 0, 0.6);
					border-radius: 10px;
					padding: 18px 10px;
					text-align: center;
					margin-right: 10px;
					// margin-bottom: 10px;
					cursor: pointer;

					&--selected {
						background: linear-gradient(0deg, #ff8585 0%, #ff7192 100%);
					}
				}
			}
		}
	}
}
