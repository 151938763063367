.app {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.form {
  width: 600px;
  padding: 40px 80px 60px;
  border-radius: 30px;
}

.wabi-input {
  border-radius: 10px;
  margin-top: 25px !important;
  margin-bottom: 5px !important;
}