@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.neumorphism-container {
  background: #1f2938;
}

.neumorphism-input {
  background: linear-gradient(145deg, #404040, #404040);
  box-shadow: inset 5.11px 5.11px 11px #404040,
    inset -5.11px -5.11px 11px #555555;
}

.neumorphism-bg {
  background: linear-gradient(145deg, #404040, #404040);
  box-shadow: inset 5.11px 5.11px 11px #404040,
    inset -5.11px -5.11px 11px #555555;
}

.neumorphism-button {
  border-radius: 10px;
  background: #333 !important;

  font-family: "Damion", cursive;
  cursor: pointer;
  border: none;
  transition: 500ms;
  padding: 10px !important;
}

.neumorphism-button:hover {
  color: #d6d6d6;
  transition: 500ms;
}

.wabi-input .MuiInputBase-input {
  color: white;
}

.wabi-input .MuiInputBase-input::placeholder {
  color: white;
  opacity: 1; /* Firefox */
}

.wabi-input
  .MuiInputBase-root.MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline,
.wabi-input
  .MuiInputBase-root.MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline,
.wabi-input
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

.wabi-button {
  background: linear-gradient(180deg, #fd6c57 0%, #fd3366 100%);
  border-radius: 50px !important;
  padding: 8px !important;
  text-transform: capitalize !important;
}

.wabi-container {
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
  padding: 8px !important;
  min-width: 1100px;
}
