@mixin resetList {
	margin: 0;
	padding: 0;
}
@mixin flexRow {
	display: flex;
	flex-direction: row;
	align-items: center;
}
@mixin flexCol {
	display: flex;
	flex-direction: column;
}
.modal-content-wrapper {
	width: 100%;
	height: 100%;
	position: relative;

	.editPartySize-content-container {
		width: 468px;
		height: 500px;
		background: rgba(0, 0, 0, 0.6);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 10px;
		border-color: none;
		padding: 20px;

		.modal-title-header {
			@include flexRow();
			align-items: center;
			font-size: 16px;
			font-weight: 500;
			color: white;
			width: 100%;
			padding-bottom: 5px;
		}
		.img-wrapper {
			display: inline-flex;
			align-content: center;
			justify-content: center;
			align-items: center;
			position: absolute;
			background-color: #ffffffd9;
			border-radius: 27%;
			top: 15px;
			right: 15px;
			cursor: pointer;
			width: 36px;
			height: 36px;
			margin: 5px;
		}

		.table-btn-wrapper {
			@include flexRow();
			justify-content: flex-end;
			// margin: 0 20px;
		}
		.party-size-input {
			height: 60px;
			width: 100%;
			// margin: 0 20px;
			background: rgba(0, 0, 0, 0.6);
			border-radius: 10px;
			color: white;
			padding: 10px;
		}
	}
}
