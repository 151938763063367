.booking-calendar {
  width: 419px;
  height: 348px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  margin: 0;
  .rdp-head_cell {
    color: #bcbcbc;
  }
  .rdp-NavBar {
    display: none;
  }
  .rdp-day_today {
    background: linear-gradient(
      0deg,
      rgba(255, 133, 133, 1) 0%,
      rgba(255, 113, 146, 1) 100%
    );
  }
  .rdp-month,
  .rdp-table {
    width: 100%;
    max-width: none;
  }
  .rdp-day {
    color: white;

    &--disabled {
      color: #9b9b9b;
    }

    &--outside {
      color: #999999;
    }

    &--today {
      font-weight: normal;
    }

    &--selected {
      &:not(.rdp-day--disabled):not(.rdp-day--outside) {
        font-weight: 500;
        background: linear-gradient(
          0deg,
          rgba(255, 133, 133, 1) 0%,
          rgba(255, 113, 146, 1) 100%
        );
      }
    }

    &:not(.rdp-day--disabled):not(.rdp-day--selected):not(
        .rdp-day--outside
      ):hover {
      background: linear-gradient(
        0deg,
        rgba(255, 133, 133, 1) 0%,
        rgba(255, 113, 146, 1) 100%
      );
    }
  }
  .rdp-Month {
    width: 100%;
    height: 320px;

    .rdp-Caption {
      display: none;
    }

    .rdp-weekdaysRow {
      .rdp-weekday {
        color: #bcbcbc;
      }
    }
  }
}
