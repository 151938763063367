.party-size-selection {
    color: white;
    position: relative;
    height: 60px;
    width: 420px;
    overflow-x: auto;
    display: inline-flex;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

    input {
        display: none;
        &:checked {

        }
    }

    .party-size-item {
        width: 60px; 
        height: 60px;
        background: rgba(0, 0, 0, 0.6); 
        // padding: 17px 25px;
        border-radius: 10px;
        margin-right: 10px;
        cursor: pointer;
        flex: none;
        text-align: center;

        &--selected {
            background: linear-gradient(0deg, rgba(255,133,133,1) 0%, rgba(255,113,146,1) 100%);
        }

        label {
            cursor: pointer;
            width: 100%;
            height: 100%;
            padding: 17px;
        }
    }
}