.table-filter {
	width: 100%;
	height: 50px;
	padding: 10px 0;
	display: flex;
	justify-content: flex-end;
	padding-right: 98px;
	color: white;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

	.table-options {
		background: rgba(0, 0, 0, 0.6);
		padding: 2px 13px;
		display: inline-block;
		border-radius: 7px;
		margin-left: 5px;
		cursor: pointer;
	}
}

.col-table-filter-scrollbar {
	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;

		border-radius: 20px;
		background-color: #2a343a;
	}

	::-webkit-scrollbar-corner {
		display: none;
	}
}
