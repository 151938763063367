.booking-list {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

  width: 100%;
  min-height: 480px;
  max-height: 520px;
  color: white;
  overflow-y: auto;
  margin-bottom: 10px;

  .booking-list-item-section {
    height: 50px;
    background: #3c444b;
    padding: 0px 8px;
    font-weight: 500;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
  }

  .booking-list-item {
    padding: 0px;
    cursor: pointer;
    padding: 8px;

    &:active {
      background: #20272b;
    }

    &--selected {
      background: #383838;
    }

    .booking-list-item-container {
      display: flex;

      .booking-list-item-time {
        width: 60px;
        background: rgba($color: #000000, $alpha: 0.8);
        border-radius: 5px;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        padding: 8px;
        justify-content: space-between;
        &--cancelled {
          background-color: rgb(112, 112, 112);
        }

        span {
          color: #fff;
        }
      }

      .booking-list-item-guest-name {
        max-width: 178px;
        min-width: 178px;
        margin-left: 10px;
        font-weight: 500;
      }

      .booking-list-item-guest-bottom {
        max-width: 178px;
        min-width: 178px;
        height: 15px;

        span {
          color: #bcbcbc;
          font-weight: 400;
          font-size: 12px;
        }
      }

      .booking-list-item-table {
        width: 75px;
        text-align: right;
        span {
          font-size: 12px;
          font-weight: 700;
          border: 1px solid white;
          padding: 3px;
          border-radius: 5px;
        }

        .booking-list-item-icons {
          margin-top: 15px;

          img {
            &:first-child {
              margin-left: 0;
            }

            margin-left: 5px;
          }
        }
      }
    }
  }
}

.table-floor-container {
  width: 100%;
  height: 100%;
  position: relative;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #222a2f;
  }
  ::-webkit-scrollbar-thumb {
    width: 1px;
    height: 1px;

    border-radius: 20px;
    background-color: #2a343a;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }

  .table-floor {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: auto;
    // flex-direction: row-reverse;
    display: flex;

    .table-container {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
      cursor: pointer;
    }
    .img-filter {
      filter: hue-rotate(181deg) brightness(1) invert(1);
    }
  }

  .table-floor-bottom {
    width: 100%;
    height: 40px;
    justify-content: flex-end;
    display: inline-flex;
    position: fixed;
    bottom: 0;
    right: 0;
    align-items: center;
    gap: 8px;

    .btn-floor {
      background: rgba(0, 0, 0, 0.8);
      cursor: pointer;
      display: inline-flex;
      padding: 8px 15px;
      color: white;
      margin-right: 10px;
      border-top-left-radius: 10px;
      font-size: 14px;

      .btn-floor-name {
        margin: 0 10px;
      }
    }

    .btn-timeline {
      width: 58px;
      height: 40px;
      background: rgba($color: #000000, $alpha: 0.8);
      padding-top: 6px;
      padding-left: 17px;
      border-top-left-radius: 10px;
      cursor: pointer;
    }
    .btn-pos {
      font-size: 0;
      background-color: #fff;
      border-radius: 6px;
      padding: 4px;
      transition: 0.3s;
      cursor: pointer;
    }
  }
}

.booking-list-container {
  width: 336px;
  input {
    color: white;
    caret-color: white;
  }
  .bg-search-bar {
    display: inline-flex;
    text-align: right;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    background: #222a2f;
    box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;

    .search-icon {
      width: 22px;
      height: 22px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: white;
    }
  }
}

.menu-btn-container {
  display: inline-flex;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .btn-menu {
    width: 40px;
    height: 40px;
    max-width: 50vh;
    max-height: 50vh;
    border-radius: 100px;
    background: #333;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    &:active {
      background: #1c2327;
    }

    &--big-size {
      width: 50px;
      height: 50px;
    }

    &--margin-top {
      margin-top: 5px;
    }

    &--margin-left {
      margin-left: 106px;
    }
  }

  .btn-arrow {
    width: 30px;
    height: 30px;
    padding: 10px;
    cursor: pointer;
  }

  .bg-shift-indicator {
    width: 30px;
    height: 30px;
    padding: 7px;
    cursor: pointer;
    border-radius: 50%;
    background: #222a2f;
    box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;

    .shift-indicator {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background: #30eb8e;

      &.no-shift {
        background: #eb3030;
      }
    }
  }

  .bg-date-shift {
    color: white;
    font-weight: 500;
    padding-top: 3px;
    height: 30px;
    width: 190px;
    border-radius: 15px;
    background: #222a2f;
    box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;

    label {
      cursor: pointer;
    }
  }

  .btn-toggle {
    flex-direction: row;
    width: 50px;
    height: 50px;
    max-width: 50vh;
    max-height: 50vh;
    border-radius: 10px;
    background: #222a2f;
    padding: 5px;
    cursor: pointer;
    box-shadow: 5px 5px 10px #1a2024, -5px -5px 10px #2a343a;
    &:active {
      background: #1c2327;
    }

    &--margin-left {
      margin-left: 20px;
    }

    .bg-icon-container {
      background: transparent;
      width: 100%;
      height: 100%;
      border-radius: 10px;

      svg {
        fill: #bcbcbc;
      }
    }

    &--selected {
      border-radius: 10px;
      background: #222a2f;
      box-shadow: inset 5px 5px 10px #1a2024, inset -5px -5px 10px #2a343a;

      .bg-icon-container {
        background: #ff8585;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: white;
        }
      }
    }
  }
}

.scroll-bar-list {
  height: 76%;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #222a2f;
  }
  ::-webkit-scrollbar-thumb {
    width: 5px;
    height: 1px;

    border-radius: 20px;
    background-color: #2a343a;
  }

  ::-webkit-scrollbar-corner {
    display: none;
  }
}

.category-button {
  background: #222a2f !important;
  padding: 8px !important;
  font-size: 12px !important;
  font-weight: bold !important;
  line-height: 1 !important;
  height: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  min-width: 130px !important;
  display: block !important;
}
.category-button.active {
  background: linear-gradient(
    90deg,
    rgba(250, 81, 134, 1) 0%,
    rgba(243, 214, 160, 1) 100%
  ) !important;
}
