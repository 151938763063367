@mixin resetList {
	margin: 0;
	padding: 0;
}
@mixin flexRow {
	display: flex;
	flex-direction: row;
	align-items: center;
}
@mixin flexCol {
	display: flex;
	flex-direction: column;
}
.modal-content-wrapper {
	width: 100%;
	height: 100%;
	position: relative;

	.editName-content-container {
		width: 630px;
		height: auto;
		background: rgba(0, 0, 0, 0.6);
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border-radius: 10px;
		border-color: none;
		padding: 20px;

		.img-wrapper {
			display: inline-flex;
			align-content: center;
			justify-content: center;
			align-items: center;
			position: absolute;
			background-color: #ffffffd9;
			border-radius: 27%;
			top: 15px;
			right: 15px;
			cursor: pointer;
			width: 36px;
			height: 36px;
			margin: 5px;
		}
		.modal-title-header {
			@include flexRow();
			align-items: center;
			font-size: 16px;
			font-weight: bold;
			color: white;
			width: 100%;
			margin-bottom: 30px;
		}
		.line-break {
			display: flex;
			width: auto;
			height: 0px;
			border-bottom: 1px solid #cccccc;
			position: relative;
			margin: 14.5px 0;
		}

		.edit-name-label {
			position: relative;
			width: 200px;
			font-weight: 500;
			left: 20px;
			color: white;
		}
		input {
			right: 0;
			position: relative;
			width: 330px;
			color: white;
			background: none;
			margin-right: 10px;
		}
	}
}
