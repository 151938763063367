.table-details-container {
  display: block;
  height: 638px;
  width: 356px;
  margin: 0;
  left: -100%;
  transition: left 200ms ease;
  position: absolute;
  overflow: hidden;
  z-index: 6;
  border-color: #222a2f;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #222a2f;
  // -webkit-box-shadow: 4px 11px 13px #161a1d, -4px -5px 12px 7px #273036;
  box-shadow: 4px 11px 13px 0px #161a1d, -4px -5px 12px 7px #273036;

  &--active {
    left: 15px;
  }

  user-select: none;

  .close-btn-wrapper {
    z-index: 1;
    margin-top: 2px;
    display: flex;
    position: absolute;
    top: 16px;
    right: 11px;
    height: 15px;
    cursor: pointer;

    &:active {
      background: #5c6469;
      color: #ffffff;
      outline: none;
      outline-offset: none;
    }

    img {
      user-select: none;
      -webkit-user-drag: none;
      -khtml-user-drag: none;
      -moz-user-drag: none;
      -o-user-drag: none;
      user-drag: none;
    }
  }

  .col1 {
    display: flex;
    background-color: transparent;
    margin: 5px 0;
    color: white;

    .row {
      align-items: center;
      padding-left: 5px;
      .dot {
        background-color: #ffffff;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        margin-right: 5px;
        margin-left: 5px;

        &--seated {
          background-color: #fe7d86;
        }
      }
      p {
        font-size: 1.5rem;
      }
    }
  }

  .col2 {
    color: white;
    padding: 0px;
    .graph-wrapper {
      display: block;
      margin: 0px;
      padding: 15px;
      background-color: #243436;

      h4 {
        padding-bottom: 10px;
        font-weight: 400;
      }
    }
  }
  .list-container {
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 0;
    .list-item {
      width: 100%;
      list-style: none;
      color: white;
    }
  }
  .tableDetail-contentWrapper {
    overflow: hidden;
    overflow-y: auto;
    height: 320px;

    .btn-walk-in-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      .btn-walk-in1 {
        background: linear-gradient(0deg, #ff8585 0%, #ff7192 100%);
        border-color: #212a2f;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 309px;
        height: 40px;
        margin-top: 10px;
        border-radius: 20px;
        cursor: pointer;
        user-select: none;
        &:active {
          background: #1f262b;
          color: #ffffff;
          outline: none;
          outline-offset: none;
        }
        &--selected {
          background: #fe7d86;
          color: white;
        }
      }
      .btn-walk-in2 {
        // background: linear-gradient(0deg, #ff8585 0%, #ff7192 100%);
        border: #fe7d86 1px solid;
        color: #fe7d86;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 309px;
        height: 40px;
        margin-top: 10px;
        border-radius: 20px;
        cursor: pointer;
        user-select: none;
        &:active {
          background: #1f262b;
          color: #ffffff;
          outline: none;
          outline-offset: none;
        }
        &--selected {
          background: #fe7d86;
          color: white;
        }
      }
    }
  }
  .tableDetail-contentWrapper-isChangingTable {
    pointer-events: none;
  }
  .col3 {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    height: 70px;
    cursor: pointer;
    user-select: none;
    padding-left: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 4px;

    &:active {
      background: #20272b;
    }

    &--selected {
      background: #383838;
    }

    .timeDisplay {
      width: 60px;
      height: 60px;
      background: rgba($color: #000000, $alpha: 0.8);
      border-radius: 5px;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      padding: 8px;
      margin-top: 3px;

      &--cancelled {
        background-color: rgb(112, 112, 112);
      }

      span {
        color: #bcbcbc;
      }
      // .span2 {
      // 	font-size: 12px;
      // 	margin-top: -5px;
      // }
    }

    .cxInfo {
      padding-left: 10px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      .span-name {
        max-width: 178px;
        min-width: 178px;
        font-weight: 500;
        color: white;
      }
      .span-no {
        margin-top: 2px;
        margin-bottom: -3px;
        font-size: 13px;
        color: #bcbcbc;
      }
      .span-guest {
        font-size: 13px;
        color: #bcbcbc;
      }
    }

    .table-no-info {
      // display: flex;
      // align-content: center;
      // justify-content: center;
      // align-items: center;
      // color: #fe7d86;
      // border: #fe7d86 solid;
      // border-radius: 5px;
      // height: 20px;
      // width: 44px;
      // border-width: thin;
      position: absolute;
      top: 7px;
      right: 5px;
      // padding: 0 0;
      width: 75px;
      text-align: right;
      span {
        font-size: 12px;
        font-weight: 700;
        border: 0.5px thin white;
        padding: 3px;
        border-radius: 5px;
      }
    }

    .noteIcon-wrapper {
      // display: flex;
      display: flex;
      position: absolute;
      bottom: -2px;
      right: -5px;
      padding: 10px;
      margin-top: 15px;

      img {
        &:first-child {
          margin-left: 0;
        }

        margin-left: 5px;
      }
    }
  }
  .footer {
    width: 100%;
    color: #bcbcbc;
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-bottom: 0px;
    // background: saddlebrown;

    .btn-control {
      z-index: 1;
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      background-color: #222a2f;
      border-color: #212a2f;
      // text-align: center;
      width: 309px;
      height: 40px;
      margin-bottom: 10px;
      border-radius: 20px;
      cursor: pointer;
      user-select: none;
      box-shadow: 13px 10px 20px 6px #161a1d9c, 1px -3px 12px 0px #c3b7b733;
      &:active {
        background: #1f262b;
        color: #ffffff;
        outline: none;
        outline-offset: none;
      }
      &--selected {
        background: #fe7d86;
        color: white;
      }
    }
    .seated-control {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      width: 339px;
      justify-content: flex-end;
      align-items: center;
      background: #222a2f;
      .btn-control {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        background-color: #222a2f;
        border-color: #212a2f;
        // text-align: center;
        width: 309px;
        height: 40px;
        margin-bottom: 10px;
        border-radius: 20px;
        cursor: pointer;
        user-select: none;
        box-shadow: 13px 10px 20px 6px #161a1d9c, 1px 1px 8px 0px #c3b7b726;
        &:active {
          background: #1f262b;
          color: #ffffff;
          outline: none;
          outline-offset: none;
        }
        &--selected {
          bottom: -8px;
          background: #fe7d86;
          color: white;
        }
      }
    }
  }
  .footer-shadow {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 0 0 2px;
    padding: 0;
    z-index: -1;
    .btn-shadow {
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      background-color: #222a2f;
      user-select: none;
      width: 309px;
      height: 37px;
      margin: 2px 0 10px 2px;
      border-radius: 20px;
      box-shadow: 1px -3px 12px 0px #c3b7b733;
    }
  }
}
.tableDetails-table-name {
  margin-left: 8px;
}
.tableDetails-footer-btn {
  color: #ffffff;
  width: 100%;
  margin: 0 !important;
  padding: 0;
  background: #222a2f !important;
  box-shadow: 4px 11px 13px 0 #161a1d, 1px 2px 12px 7px #273036 !important;
}
