.select-booking-calendar-container {
	width: 100%;
	height: 100%;
	position: relative;

	.select-booking-calendar-main-container {
		width: 549px;
		height: 547px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		text-align: center;
		padding: 64px;
	}
}
