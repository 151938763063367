.time-table {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */

	width: 420px;
	height: 132px;
	// display: 'flex';
	// justify-content: 'flex-end';
	color: white;
	overflow-x: auto;
	overflow-y: hidden;

	.time-table-item-container {
		display: grid;
		grid-template-rows: auto auto;
		grid-auto-columns: 132px;
		grid-auto-rows: 60px;
		gap: 0px 7px;
		grid-auto-flow: column;

		.time-table-item {
			width: 132px;
			height: 60px;
			background: rgba(0, 0, 0, 0.6);
			border-radius: 10px;
			padding: 18px 10px;
			text-align: center;
			margin-right: 10px;
			margin-bottom: 10px;
			cursor: pointer;

			&--selected {
				background: linear-gradient(0deg, #ff8585 0%, #ff7192 100%);
			}
		}
	}
}

.row-time-table-scrollbar {
	::-webkit-scrollbar {
		width: 5px;
		height: 5px;
		background-color: #222a2f;
	}
	::-webkit-scrollbar-thumb {
		width: 1px;
		height: 1px;

		border-radius: 20px;
		background-color: #2a343a;
	}

	::-webkit-scrollbar-corner {
		display: none;
	}
}
